

.t-selector {
  //background: var(--selector-background);
  display: flex;
  //border-radius: 8px;
  line-height: 24px;
  width: fit-content;
  align-items: center;
  max-height: 38px;
  position: relative;

  padding: 2px 0;
  background: var(--card-background);
  border-radius: 12px;

  &__item {
    height: 100%;
    border-radius: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid transparent;
    flex: 1;
    //width: 115px;
    padding: 16px 20px;
    color: var(--selected-big-text);
    cursor: pointer;
    font-weight: 500;

    &-icon {
      margin-right: 8px;
    }

    &--selected {
      //border: var(--selector-big-selected-border);
      color: var(--selector-big-selected-text);
      background: var(--selector-big-selected-background);
    }
  }

  &__border {
    position: absolute;
    bottom: 0;  // Adjust depending on where you want the border to appear
    left: 0;
    //width: calc(50%);
    height: calc(100% - 4px);  // Thickness of the sliding border
    border: 2px solid var(--selector-big-selected-border);  // Color of the sliding border
    transition: all 0.3s ease-in-out;
    border-radius: 12px;
    //z-index: -1;
  }
}

@media screen and (max-width: 480px) {

  .t-selector {
    border: 1px solid var(--selector-big-mobile-border-color);
    //border: 1px solid red;

    &__item {
      padding: 8px 16px;
    }

    &__border {
      height: calc(100% - 2px);
      bottom: -1px;
    }
  }
}


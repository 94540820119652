
.apps-search-not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  color: var(--apps-navigation-inactive-color);
  height: calc(100vh - 260px - 44px - 40px - 55px - 18px - 20px - 24px);

  &__text {
    font-size: 16px;
    font-weight: 400;
    color: var(--apps-navigation-inactive-color);
  }
}

//@media screen and (max-width: 480px) {
//  .apps-search-not-found {
//    //height: calc(100vh - 260px - 44px - 40px - 55px - 18px - 20px);
//  }
//}

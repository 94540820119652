

.apps-list {
  margin-top: 20px;

  &__info {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;

    .nav-btn {
      user-select: none;
      box-sizing: border-box;
      font-size: 14px;
      display: flex;
      align-items: center;
      cursor: pointer;
      background: var(--apps-navigation-btn-background);
      padding: 10px 15px 10px 12px;
      border-radius: 24px;
      color: var(--app-list-nav-btn-text);
      border: 1px solid var(--apps-navigation-btn-border-color);
      max-height: 44px;
      min-height: 44px;

      &__link {
        display: block;
        max-height: 44px;
      }

      &:active {
        background: var(--apps-navigation-btn-active-background-color);
      }

      &__icon {
        width: 20px;
        height: 20px;
        color: inherit;
      }

      &__icon-dropdown--open {
        transform: rotate(180deg) scaleX(-1);
      }

      &__text {
        font-size: 14px;
        margin-left: 8px;
        line-height: 20px;
        //padding-top: 1px;
        color: inherit;
      }

      &--open {
        border: 2px solid var(--apps-navigation-btn-selected-border-color);
        padding: 10px 14px 10px 11px;
        //background: rgba(0, 101, 245, 0.1);
        background: var(--apps-navigation-btn-selected-background);
        color: var(--apps-navigation-btn-selected-text-color);
      }
    }
  }

  &__nav {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    overflow: hidden;
    max-height: 44px;
    transition-property: max-height;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;

    &--open {
      max-height: 500px;
    }
  }

  &__amount {
    font-size: 14px;
    line-height: 32px;
    text-transform: uppercase;
  }

  &__card {
    margin-bottom: 20px;
  }

  &__filter-selector {
    display: flex;
    align-items: center;
    position: absolute;
    right: 8px;
  }

  &__not-found {
    height: 50vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}



@media screen and (max-width: 480px) {

  .t-col:first-child .apps-list__card-link .apps-list__card {
    border-top: 1px solid var(--card-border-color);
  }

  .apps-list {
    margin-top: 0;

    &__info {
      margin: 12px 0 12px 0;
    }

    &__card {
      margin: 0 -16px;
      border-radius: 0;
      border-top: none;
      border-right: none;
      border-left: none;
    }
  }
}

@media screen and (max-width: 580px) {
  .t-col {

  }
}



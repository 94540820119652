

$search-box-border-size: 2px;

.search-container {
  position: relative;
  width: calc(100% - 4px);

  .search-focused {
    //background: var(--indexpage-search-background-color);
    border-color: var(--app-search-field-focused-border-color)!important;
  }

  &__box {
    display: flex;
    align-items: center;
    width: 100%;
    height: calc(42px - $search-box-border-size * 2);
    //background: #EDEEF0;
    //color: #24292F;
    color: inherit;
    border-radius: 12px;

    background: var(--indexpage-search-background-color);
    border: $search-box-border-size solid var(--card-border-color);
    //box-shadow: 0 0.5rem 1.2rem var(--card-box-shadow-color);

    .search-icon {
      margin: 16px 9px 16px 16px;
      color: var(--app-search-icon);
    }

    .search-input {
      flex: 1;
      border: none;
      background: transparent;
      color: inherit;
      outline: none;
      font-size: 16px;
      padding: 0;

      &::placeholder {
        color: #939394;
      }
      &::-webkit-input-placeholder {
        color: #939394;
      }
    }
  }
}

.search-dropdown {
  right: 0;
}

.filter-button {
  padding: 14px 16px;
  cursor: pointer;
  border-left: 2px solid var(--card-border-color);
}




.settings-sheet {

  &__title {
    color: var(--body-text-color);
    font-weight: 600;
    font-size: 18px;
  }

  //&__reset-btn {
  //  font-size: 18px;
  //  color: var(--body-muted-text-color);
  //}
  //

  &__close {
    color: var(--blue-bright);
    padding-right: 4px;
  }

}


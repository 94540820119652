

.apps-navigation {
  display: flex;
  flex-direction: column;

  &__item {
    padding-left: 10px;
    display: flex;
    align-items: center;
    height: 48px;
    color: var(--apps-navigation-inactive-color);
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }

    &--active {
      color: var(--apps-navigation-category-active-color);

      & svg {
        color: var(--apps-navigation-icon-active);
      }
    }

    .item__wrapper {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;

      .item__name {
        margin-left: 16px;
        line-height: 24px;
      }
    }
  }
}

.apps-navigation--window {
  @extend .apps-navigation;

  width: 240px;
  padding: 16px;
  background: var(--card-background);
  border: 1px solid var(--card-border-color);
  position: absolute;

  // TODO: FIX IT TEMPORARY SOLUTION
  bottom: -515px;
  left: -133px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}





$app-page-padding-x: 40px;
$app-page-padding-y: 32px;

.app {
  padding:
      $app-page-padding-y
      $app-page-padding-x
      0
      $app-page-padding-x;
  background: var(--card-background);
  border: 1px solid var(--card-border-color);
  border-radius: 12px;
  margin-top: 20px;
  position: relative;
  overflow-y: hidden;
  overflow-x: auto;

  &__header {
    display: flex;
  }

  &__logo {
    width: 128px;
    height: 128px;
    border-radius: 32px;
    background-size: cover;
    flex-shrink: 0;
  }

  &__heading {
    margin-left: 20px;
  }

  &__title {
    font-size: 24px;
    display: flex;
    gap: 9px;
    align-items: center;
    margin: 0;
    min-height: 24px; // For skeleton
  }

  &__short-description {
    margin-top: 12px;
    font-weight: 400;
    color: var(--app-short-description);

    // For skeleton loader
    min-width: 180px;
  }

  &__open-btn {
    margin-top: 14px;

    & a {
      display: block;
      width: fit-content;
    }
  }

  &__open-btn--wide {
    margin-top: 24px;

    & button {
      text-transform: uppercase;
    }
  }

  &__open-btn button {
    text-transform: uppercase;
    height: 40px;
    font-size: 14px;
  }

  &__open-btn a {
    text-decoration: none;
  }

  &__description {
    margin-top: 20px;
    max-width: 580px;
    font-weight: 400;
    line-height: 24px;
    color: var(--body-text-color);

    &--loading {
      width: 100%;
      min-height: 115px;
    }
  }

  &__screenshots {
    margin-right: -$app-page-padding-x;
    margin-top: 32px;
    margin-bottom: 32px;
    gap: 16px;
    overflow: auto;
    white-space: nowrap;
    display: flex;
    padding-right: 40px;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__screenshot {
    border-radius: 12px;
    background-size: cover;

    &--portrait {
      width: 210px;
      height: 372px;
    }

    &--landscape {
      width: 372px;
      height: 210px;
    }

    div {
      border-radius: 12px;
    }
  }

  &__delimiter {
    background: var(--card-border-color);
    height: 1px;
    margin: 32px -40px 0 -40px;
  }

  &__info {
    margin: 32px 0;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    padding-right: 40px;
    gap: 50px;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .item__name {
      color: var(--app-info-name);
      font-weight: 500;
    }

    .item__value {
      margin-top: 8px;
      font-weight: 400;

      .value-wrapper {
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;
      }

      & svg {
        color: var(--icon-color);
        width: 16px;
        height: 16px;

        &:hover {
          color: var(--icon-hover-color);
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {

  $app-page-mobile-padding-x: 16px;
  $app-page-mobile-padding-y: 24px;

  .app {
    padding:
        $app-page-mobile-padding-y
        $app-page-mobile-padding-x
        0
        $app-page-mobile-padding-x;
    border: none;
    border-radius: 0;
    margin-left: -$app-page-mobile-padding-x;
    margin-top: 12px;
    width: 100%;
    border-top: 1px solid var(--card-border-color);
    border-bottom: 1px solid var(--card-border-color);

    &__delimiter {
      margin: $app-page-mobile-padding-y -16px $app-page-mobile-padding-y -16px;
    }

    &__open-btn {
      margin-top: 10px;
    }

    &__logo {
      width: 96px;
      height: 96px;
      border-radius: 24px;
    }

    &__description {
      margin-top: 24px;
    }

    &__screenshots {
      margin-top: 24px;
      margin-right: -$app-page-mobile-padding-x;
      padding-right: 16px;
    }

  }
}

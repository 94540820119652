
.apps-list__card {
  margin-bottom: 20px;
  //margin: 0 -8px;
  //border-radius: 0;
  //border-bottom: none;
}

@media screen and (max-width: 480px) {
  .apps-list__card {
    //margin-bottom: 20px;
    margin: 0 -16px;
    border-radius: 0;
    border-bottom: none;
  }

}


.t-col {
  position: relative;
  flex: 0 0 auto;
  width: 100%;
  box-sizing: border-box;

  //flex-basis: 0;
  //flex-grow: 1;
  //max-width: 100%;
}
//
//@media screen and (max-width: 480px) {
//
//}

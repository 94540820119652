

// Element that can be used to drag our sheet
.draggable-place {
  //padding: 1.5rem
}

.bottom-sheet {
  transition: 0.1s ease-in-out;

  &__header {
    display: flex;
    padding: 20px 16px;
    justify-content: space-between;
    align-items: center;
    position: relative;

    &-middle {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    &-right {
      //
    }

    &-left {
      //
    }
  }

  &__content {
    padding: 4px 0 0 0;
    overflow-y: scroll;
  }
}

.bottom-sheet .show {
  //pointer-events: auto;
}

.sheet-content {
  //max-width: 400px;
  //margin: 0 auto;
  touch-action: none;
  //padding: 0 1.5rem;
  z-index: 9999;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--card-background);
  border-radius: 1.5rem 1.5rem 0 0;
  height: 90vh;
  transition: 0.2s ease-in-out;
  transform: translateY(100%);
  will-change: height;
}

.sheet-content__wrapper {
  max-height: fit-content;
}

.bottom-sheet.show .sheet-content{
  transform: translateY(0%);
  //will-change: transform;
}
.bottom-sheet.dragging .sheet-content {
  transition: none;
}

// Overlay to detect click outside of our sheet
.bottom-sheet .sheet-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.sheet-overlay {
  background: #000;
  opacity: 0.2;
  z-index: 201;
}

.drag-icon {
  margin: 0 auto 1rem auto;
  background: #777777;
  border-radius: 10px;
  height: 7px;
  width: 35px;
}



$header-padding: 20px;

::v-deep .settings-modal {
  color: var(--body-text-color);
  background: var(--card-background);
  border-radius: 16px;
  position: relative;
  width: 480px;

  &__close {
    cursor: pointer;
    position: absolute;
    right: $header-padding;
    top: $header-padding;
    max-height: 24px;

    svg {
      height: 24px;
      width: 24px;
    }
  }

  &__header {
    padding: $header-padding;
    display: flex;
    justify-content: center;
    align-items: center;

    &-title {
      font-size: 20px;
      text-transform: uppercase;
      font-weight: 500;
    }
  }

  &__content {
    padding: $header-padding * 2;
    display: flex;
    gap: 32px;
    flex-direction: column;
  }

  &__section-title {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    color: var(--body-muted-text-color);
    margin-bottom: 16px;
  }
}


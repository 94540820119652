
.t-dropdown {
  position: relative;
  margin-top: 8px;
}

.t-dropdown-menu {
  position: absolute;
  // TMP FIX
  // TODO: Refactor styling
  right: 0;
  z-index: 2;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.35s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

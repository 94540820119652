
//::v-deep .t-selector__item {
//  //position: relative;
//
//  .t-delimiter {
//    position: absolute;
//    height: 32px;
//    right: 0;
//  }
//}

::v-deep .t-selector {

  & .t-selector__item {
    padding: 0!important;
  }

  & .t-selector__item--selected {


    & .t-selector__item-name, & .t-selector__item-icon {
      color: var(--blue-bright)!important;
    }
  }

  & .t-selector__item-name {
    color: var(--body-text-color)!important;
  }

  background: var(--body-background)!important;
}




.apps {
  font-weight: 500;
  margin-top: 2px;

  &__content {
    padding-left: 20px;
  }

  &__search-filter-wrapper {
    display: flex;
    gap: 15px;
  }
}

@media screen and (max-width: 1240px) {
  .apps {
    &__content {
      padding: 0 8px;
      border-radius: 0;
    }
  }
}

